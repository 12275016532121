.flex {
  display: flex;
  align-items: flex-start;
}

.flex .header {
  margin-bottom: 0;
}

.flex .spacer {
  flex: 1;
}
