canvas {
  max-width: 100%;
}

.Snapshot {
  width: 25%;
  float: left;
  position: relative;
}
.Snapshot .snapshot-remove {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.Snapshot:hover .snapshot-remove {
  display: block;
  opacity: 0.5;
}
.Snapshot:hover .snapshot-remove:hover {
  opacity: 1;
}
